"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileBodyToJSON = exports.UpdateProfileBodyFromJSONTyped = exports.UpdateProfileBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function UpdateProfileBodyFromJSON(json) {
    return UpdateProfileBodyFromJSONTyped(json, false);
}
exports.UpdateProfileBodyFromJSON = UpdateProfileBodyFromJSON;
function UpdateProfileBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': _1.PhoneNumberBodyFromJSON(json['phoneNumber']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'profilePictureID': !runtime_1.exists(json, 'profilePictureID') ? undefined : json['profilePictureID'],
        'bio': !runtime_1.exists(json, 'bio') ? undefined : json['bio'],
    };
}
exports.UpdateProfileBodyFromJSONTyped = UpdateProfileBodyFromJSONTyped;
function UpdateProfileBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'phoneNumber': _1.PhoneNumberBodyToJSON(value.phoneNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'profilePictureID': value.profilePictureID,
        'bio': value.bio,
    };
}
exports.UpdateProfileBodyToJSON = UpdateProfileBodyToJSON;
