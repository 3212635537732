import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	onToggle: (val: any) => void;
	disabled?: boolean;
	name: string;
	value: string;
	checked: boolean;
}

const FrameOneRadio: React.FC<IProps> = (props) => {

	/**
	 * onClick helper for when clicking on a part of the component
	 * that is not directly the input itself.
	 *
	 * Don't call the toggle function if this input is disabled.
	 *
	 */
	function onClickHelper(): void {
		if (!props.disabled) {
			props.onToggle(props.value);
		}
	}

	return (
		<div
			className={classNames("frame-one-radio", {
				"frame-one-radio_disabled": props.disabled
			})}
			onClick={onClickHelper}
		>

			<div>
				<div className="pretty p-default p-round p-smooth">
					<input
						type="radio"
						checked={props.checked}
						onChange={onClickHelper}
						disabled={props.disabled}
						name={props.name}
					/>

					<div className="state p-darkPurple">
						<label>
							{/*{props.children}*/}
						</label>
					</div>
				</div>
			</div>

			<label className="frame-one-radio_real-label">
				{props.children}
			</label>
		</div>
	);
};

export default FrameOneRadio;
