import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {Investor} from "client";
import ManageInvestorsDeleteModal from "../../modals/ManageInvestorsDeleteModal";
import {FiTrash2} from "react-icons/all";

interface IProps {
	investor: Investor;
	onDone: () => void;
}

const ManageInvestorsDeleteCell: React.FC<IProps> = (props) => {

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	/**
	 * Show or hide the delete confirmation modal.
	 *
	 */
	function toggleShowDeleteModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	/**
	 * Hide the delete confirmation modal and call the
	 * props.onDone function to update the table.
	 *
	 */
	function onDoneDeleteInvestor(): void {
		setShowDeleteModal(false);
		props.onDone();
	}

	return (
		<React.Fragment>
			<ManageInvestorsDeleteModal
				isOpen={showDeleteModal}
				investor={props.investor}
				onClose={toggleShowDeleteModal}
				onDone={onDoneDeleteInvestor}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					icon={FiTrash2}
					color="darkPurple"
					onClick={toggleShowDeleteModal}
				>
					Delete
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default ManageInvestorsDeleteCell;
