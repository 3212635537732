"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingStatusToJSON = exports.ListingStatusFromJSONTyped = exports.ListingStatusFromJSON = exports.ListingStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ListingStatus;
(function (ListingStatus) {
    ListingStatus["APPROVED"] = "APPROVED";
    ListingStatus["PENDING"] = "PENDING";
    ListingStatus["DECLINED"] = "DECLINED";
    ListingStatus["COMPLETED"] = "COMPLETED";
    ListingStatus["DEPRECATED"] = "DEPRECATED";
})(ListingStatus = exports.ListingStatus || (exports.ListingStatus = {}));
function ListingStatusFromJSON(json) {
    return ListingStatusFromJSONTyped(json, false);
}
exports.ListingStatusFromJSON = ListingStatusFromJSON;
function ListingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ListingStatusFromJSONTyped = ListingStatusFromJSONTyped;
function ListingStatusToJSON(value) {
    return value;
}
exports.ListingStatusToJSON = ListingStatusToJSON;
