"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFavoriteUsersResponseToJSON = exports.GetFavoriteUsersResponseFromJSONTyped = exports.GetFavoriteUsersResponseFromJSON = void 0;
const _1 = require("./");
function GetFavoriteUsersResponseFromJSON(json) {
    return GetFavoriteUsersResponseFromJSONTyped(json, false);
}
exports.GetFavoriteUsersResponseFromJSON = GetFavoriteUsersResponseFromJSON;
function GetFavoriteUsersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'favoriteUsers': (json['favoriteUsers'].map(_1.FavoriteUserFromJSON)),
    };
}
exports.GetFavoriteUsersResponseFromJSONTyped = GetFavoriteUsersResponseFromJSONTyped;
function GetFavoriteUsersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'favoriteUsers': (value.favoriteUsers.map(_1.FavoriteUserToJSON)),
    };
}
exports.GetFavoriteUsersResponseToJSON = GetFavoriteUsersResponseToJSON;
