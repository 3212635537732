"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingCategoryToJSON = exports.ListingCategoryFromJSONTyped = exports.ListingCategoryFromJSON = exports.ListingCategory = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ListingCategory;
(function (ListingCategory) {
    ListingCategory["ProductType"] = "productType";
    ListingCategory["DietaryRestrictions"] = "dietaryRestrictions";
    ListingCategory["PlaceOfOrigin"] = "placeOfOrigin";
})(ListingCategory = exports.ListingCategory || (exports.ListingCategory = {}));
function ListingCategoryFromJSON(json) {
    return ListingCategoryFromJSONTyped(json, false);
}
exports.ListingCategoryFromJSON = ListingCategoryFromJSON;
function ListingCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ListingCategoryFromJSONTyped = ListingCategoryFromJSONTyped;
function ListingCategoryToJSON(value) {
    return value;
}
exports.ListingCategoryToJSON = ListingCategoryToJSON;
