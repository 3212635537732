import React, {ReactNode, useEffect, useState} from "react";
import {GetInvestorsResponse, Investor, InvestorsApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import {Button} from "reactstrap";
import CreateInvestorModal from "../components/modals/CreateInvestorModal";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import moment from "moment";
import ManageInvestorsEditCell from "../components/tables/cells/ManageInvestorsEditCell";
import ManageInvestorsDeleteCell from "../components/tables/cells/ManageInvestorsDeleteCell";
import ManageInvestorsCopyLinkCell from "../components/tables/cells/ManageInvestorsCopyLinkCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageInvestors: React.FC<IProps> = (props) => {

	const [showNewInvestorModal, setShowNewInvestorModal] = useState(false);
	const [investors, setInvestors] = useState<GetInvestorsResponse>(undefined)
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		readInvestors().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Changes the pagination object and forces another api call.
	 *
	 */
	function resetPagination(): void {
		setFrontendPagination({
			...defaultFrontendPagination,
			renderKey: frontendPagination?.renderKey + 1,
		});
	}

	/**
	 * Show or hide the Create Investor Modal.
	 *
	 */
	function toggleNewInvestorModal(): void {
		setShowNewInvestorModal(!showNewInvestorModal);
	}

	/**
	 * Hide the Create Investor Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneNewInvestorModal(): void {
		setShowNewInvestorModal(false);
		readInvestors().then().catch();
	}

	/**
	 * Call api to get list of investors.
	 *
	 */
	async function readInvestors(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new InvestorsApi(getConfig(props.fullToken)).getInvestors({
				limit: frontendPagination?.limit,
				offset: frontendPagination?.offset,
			});

			setInvestors(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer for the Copy Link cells.
	 *
	 * @param value
	 * @param investor
	 */
	function makeCopyLinkCell(value: never, investor: Investor): ReactNode {
		return (
			<ManageInvestorsCopyLinkCell investor={investor}/>
		);
	}

	/**
	 * Renderer for the Edit cells.
	 *
	 * @param value
	 * @param investor
	 */
	function makeEditInvestorCell(value: never, investor: Investor): ReactNode {
		return (
			<ManageInvestorsEditCell
				investor={investor}
				onDone={readInvestors}
			/>
		);
	}

	/**
	 * Renderer for the Edit cells.
	 *
	 * @param value
	 * @param investor
	 */
	function makeDeleteInvestorCell(value: never, investor: Investor): ReactNode {
		return (
			<ManageInvestorsDeleteCell
				investor={investor}
				onDone={resetPagination}
			/>
		);
	}

	return (
		<React.Fragment>
			<CreateInvestorModal
				isOpen={showNewInvestorModal}
				onClose={toggleNewInvestorModal}
				onDone={onDoneNewInvestorModal}
			/>

			<PageHeader>
				<h3>
					Manage Investors
				</h3>

				<p>
					A list of all the investors added to the system. Add new ones, or make changes to existing ones.
				</p>

				<Button
					color="darkPurple"
					onClick={toggleNewInvestorModal}
				>
					Create New Investor
				</Button>
			</PageHeader>

			<div className="p-3">
				<FrameOneTableContainer
					data={investors?.investors}
					pagination={{
						...investors?.paginationInfo,
						...frontendPagination
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "Created",
							headerValue: "createdAt",
							showSortIcons: false,
							sortable: false,
							valueFormatter: (c: number) => moment(c).format("MMM D YYYY")
						},
						{
							key: "name",
							headerValue: "Name",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "walletAddress",
							headerValue: "Wallet Address",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "tokens",
							headerValue: "Tokens",
							showSortIcons: false,
							sortable: false,
							valueFormatter: (t) => (new Intl.NumberFormat("en-US")).format(t),
						},
						{
							key: undefined,
							headerValue: "Link",
							showSortIcons: false,
							sortable: false,
							cellRender: makeCopyLinkCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: undefined,
							headerValue: "Edit Investor",
							showSortIcons: false,
							sortable: false,
							cellRender: makeEditInvestorCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: undefined,
							headerValue: "Delete Investor",
							showSortIcons: false,
							sortable: false,
							cellRender: makeDeleteInvestorCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageInvestors);
