import React, {ReactNode} from "react";
import Toggle from "react-toggle";

interface IProps {
	name: string;
	checked: boolean;
	children: ReactNode;
	onChange: () => void;
}

const FrameOneToggle: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-toggle">
			<Toggle
				id={props.name}
				checked={props.checked}
				defaultChecked={false}
				icons={false}
				onChange={props.onChange}
			/>

			<label htmlFor={props.name}>
				{props.children}
			</label>
		</div>
	);
};

export default FrameOneToggle;
