import React, {ChangeEventHandler, useState} from "react";
import {CreateAdminBody, InvestorBody, InvestorsApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {Input, Label} from "reactstrap";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

export const defaultCreateInvestorForm: InvestorBody = {
	name: "",
	walletAddress: "",
	tokens: undefined,
}

const CreateInvestorModal: React.FC<IProps> = (props) => {

	const [form, setForm] = useState<InvestorBody>(defaultCreateInvestorForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultCreateInvestorForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof InvestorBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Dynamic onChange for the number format inputs.
	 *
	 * @param key
	 */
	function numberInputOnChange(key: keyof InvestorBody): (values: NumberFormatValues) => void {
		return (values) => {
			setForm({
				...form,
				[key]: values.floatValue,
			});
		}
	}

	/**
	 * Call api to create new admin,
	 * reset form & close modal on success.
	 *
	 * @param e
	 */
	async function submitNewInvestor(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new InvestorsApi(getConfig(props.fullToken)).createInvestor({
				investorBody: {
					name: form?.name || undefined,
					walletAddress: form?.walletAddress || undefined,
					tokens: form?.tokens || undefined,
				},
			});

			setForm(defaultCreateInvestorForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="md"
		>
			<FrameModalHeader
				title="Add Investor"
				toggle={closeHelper}
			/>

			<form onSubmit={submitNewInvestor}>
				<FrameModalBody>
					<Label>
						Investor Name*
					</Label>
					<Input
						placeholder="Investor Name..."
						value={form?.name}
						onChange={dynamicOnChange("name")}
						className="mb-3"
					/>

					<Label>
						Wallet Address*
					</Label>
					<Input
						placeholder="Wallet Address..."
						value={form?.walletAddress}
						onChange={dynamicOnChange("walletAddress")}
						className="mb-3"
					/>

					<Label>
						Tokens*
					</Label>
					<NumberFormat
						placeholder="Tokens..."
						value={form?.tokens}
						customInput={Input}
						allowLeadingZeros={false}
						allowNegative={false}
						decimalScale={0}
						onValueChange={numberInputOnChange("tokens")}
						className="mb-3"
					/>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitNewInvestor}
					>
						Add Investor
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateInvestorModal);
