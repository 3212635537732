"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class ListingsApi extends runtime.BaseAPI {
    /**
     * Used by admins to audit a listing
     */
    async auditListingRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling auditListing.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}/audit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AuditListingBodyToJSON(requestParameters.auditListingBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to audit a listing
     */
    async auditListing(requestParameters) {
        await this.auditListingRaw(requestParameters);
    }
    /**
     * Used by users to creating new listings
     */
    async createListingRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateListingBodyToJSON(requestParameters.createListingBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListingFromJSON(jsonValue));
    }
    /**
     * Used by users to creating new listings
     */
    async createListing(requestParameters) {
        const response = await this.createListingRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by users to disabled a listing
     */
    async disableListingRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling disableListing.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to disabled a listing
     */
    async disableListing(requestParameters) {
        await this.disableListingRaw(requestParameters);
    }
    /**
     */
    async getListingRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getListing.');
        }
        const queryParameters = {};
        if (requestParameters.coords) {
            queryParameters['coords'] = requestParameters.coords;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListingFromJSON(jsonValue));
    }
    /**
     */
    async getListing(requestParameters) {
        const response = await this.getListingRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getListingCategoryOptionsRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ListingCategoryOptionsFromJSON));
    }
    /**
     */
    async getListingCategoryOptions() {
        const response = await this.getListingCategoryOptionsRaw();
        return await response.value();
    }
    /**
     */
    async getListingsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getListings.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getListings.');
        }
        const queryParameters = {};
        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }
        if (requestParameters.coords) {
            queryParameters['coords'] = requestParameters.coords;
        }
        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }
        if (requestParameters.productTypes) {
            queryParameters['productTypes'] = requestParameters.productTypes;
        }
        if (requestParameters.dietaryRestrictions) {
            queryParameters['dietaryRestrictions'] = requestParameters.dietaryRestrictions;
        }
        if (requestParameters.priceRange) {
            queryParameters['priceRange'] = requestParameters.priceRange;
        }
        if (requestParameters.rating !== undefined) {
            queryParameters['rating'] = requestParameters.rating;
        }
        if (requestParameters.expiration !== undefined) {
            queryParameters['expiration'] = requestParameters.expiration;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getListings(requestParameters) {
        const response = await this.getListingsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getListingsForSellerRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getListingsForSeller.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getListingsForSeller.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getListingsForSeller.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/listings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getListingsForSeller(requestParameters) {
        const response = await this.getListingsForSellerRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getListingsFromWatchListRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getListingsFromWatchList.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getListingsFromWatchList.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/watchlist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getListingsFromWatchList(requestParameters) {
        const response = await this.getListingsFromWatchListRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getOwnListingsRaw(requestParameters) {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status', 'Required parameter requestParameters.status was null or undefined when calling getOwnListings.');
        }
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getOwnListings.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getOwnListings.');
        }
        const queryParameters = {};
        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/listings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getOwnListings(requestParameters) {
        const response = await this.getOwnListingsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPromotedListingsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getPromotedListings.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getPromotedListings.');
        }
        const queryParameters = {};
        if (requestParameters.coords) {
            queryParameters['coords'] = requestParameters.coords;
        }
        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/promoted`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getPromotedListings(requestParameters) {
        const response = await this.getPromotedListingsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getSimilarListingsRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getSimilarListings.');
        }
        const queryParameters = {};
        if (requestParameters.coords) {
            queryParameters['coords'] = requestParameters.coords;
        }
        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}/similar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ListingFromJSON));
    }
    /**
     */
    async getSimilarListings(requestParameters) {
        const response = await this.getSimilarListingsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getTrendingListingsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getTrendingListings.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getTrendingListings.');
        }
        const queryParameters = {};
        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }
        if (requestParameters.coords) {
            queryParameters['coords'] = requestParameters.coords;
        }
        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/trending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetListingsResponseFromJSON(jsonValue));
    }
    /**
     */
    async getTrendingListings(requestParameters) {
        const response = await this.getTrendingListingsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to set the priority of a listing
     */
    async setListingPriorityRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling setListingPriority.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}/priority`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.SetListingPriorityBodyToJSON(requestParameters.setListingPriorityBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListingFromJSON(jsonValue));
    }
    /**
     * Used to set the priority of a listing
     */
    async setListingPriority(requestParameters) {
        const response = await this.setListingPriorityRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to update existing listings
     */
    async updateListingRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateListing.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("StandardUser", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateListingBodyToJSON(requestParameters.createListingBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListingFromJSON(jsonValue));
    }
    /**
     * Used to update existing listings
     */
    async updateListing(requestParameters) {
        const response = await this.updateListingRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to update listing category options
     */
    async updateListingCategoryOptionsRaw(requestParameters) {
        if (requestParameters.category === null || requestParameters.category === undefined) {
            throw new runtime.RequiredError('category', 'Required parameter requestParameters.category was null or undefined when calling updateListingCategoryOptions.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/listings/categories/{category}`.replace(`{${"category"}}`, encodeURIComponent(String(requestParameters.category))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateListingCategoryOptionsBodyToJSON(requestParameters.updateListingCategoryOptionsBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ListingCategoryOptionsFromJSON(jsonValue));
    }
    /**
     * Used to update listing category options
     */
    async updateListingCategoryOptions(requestParameters) {
        const response = await this.updateListingCategoryOptionsRaw(requestParameters);
        return await response.value();
    }
}
exports.ListingsApi = ListingsApi;
