"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestorBodyToJSON = exports.InvestorBodyFromJSONTyped = exports.InvestorBodyFromJSON = void 0;
function InvestorBodyFromJSON(json) {
    return InvestorBodyFromJSONTyped(json, false);
}
exports.InvestorBodyFromJSON = InvestorBodyFromJSON;
function InvestorBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'walletAddress': json['walletAddress'],
        'tokens': json['tokens'],
    };
}
exports.InvestorBodyFromJSONTyped = InvestorBodyFromJSONTyped;
function InvestorBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'walletAddress': value.walletAddress,
        'tokens': value.tokens,
    };
}
exports.InvestorBodyToJSON = InvestorBodyToJSON;
