import React from "react";
import {FiX} from "react-icons/all";
import classNames from "classnames";

interface IProps {
	children: string;
	onClick?: () => void;
	className?: string;
}

const LabelOutline: React.FC<IProps> = (props) => {

	function clickHelper(): void {
		if (props.onClick) {
			props.onClick();
		}
	}

	return (
		<div className="label-outline-container">
			<span
				className={classNames("label-outline-container_inner", props.className, {
					"label-outline-container_inner_clicker": props.onClick !== undefined,
				})}
				onClick={clickHelper}
			>
				{props.children}

				{props.onClick && (
					<FiX className="label-outline-container_inner_icon"/>
				)}
			</span>
		</div>
	);
};

export default LabelOutline;
