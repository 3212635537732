import React from "react";
import {BsCardImage} from "react-icons/all";
import classNames from "classnames";

interface IProps {
	src?: string;
	alt?: string;
	className?: string;
	onClick?: () => void;
}

const CompanyPhoto: React.FC<IProps> = (props) => {

	function onClickHelper(): void {
		if (props.onClick !== undefined) {
			props.onClick();
		}
	}

	return (
		<div
			className={classNames("company-photo", props.className, {
				"company-photo_clicker": props.onClick != undefined,
			})}
			onClick={onClickHelper}
		>
			{props.src ? (
				<img
					src={props.src}
					alt={props.alt}
					className="company-photo_image"
				/>
			) : (
				<BsCardImage className="company-photo_placeholder"/>
			)}
		</div>
	);
};

export default CompanyPhoto;
