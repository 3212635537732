import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Container} from "reactstrap";
import AddUserModal from "../components/modals/AddUserModal";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {GetUsersResponse, StandardUser, Token, User, UsersApi, UserType} from "client";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {convertUserDisabledStatusToEnabledString} from "../utils/convertUserDisabledStatusToEnabledString";
import ManageUsersEditPasswordCell from "../components/tables/cells/ManageUsersEditPasswordCell";
import ManageUsersToggleEnableCell from "../components/tables/cells/ManageUsersToggleEnableCell";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageUsers: React.FC<IProps> = (props: IProps) => {

	const [showNewUserModal, setShowNewUserModal] = useState(false);
	const [users, setUsers] = useState<GetUsersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		readUsers().then().catch();
	}, []);

	/**
	 * Show or Hide the Create User Modal.
	 *
	 */
	function toggleNewUserModal(): void {
		setShowNewUserModal(!showNewUserModal);
	}

	/**
	 * Hide the Create User Modal when finished,
	 * and call api to get the updated list.
	 *
	 */
	function onDoneNewUserModal(): void {
		setShowNewUserModal(false);
		readUsers().then().catch();
	}

	/**
	 * Call api to get & save the list of admins.
	 *
	 */
	async function readUsers(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getUsers({
				limit: 10000,
				offset: 0,
				type: [UserType.STANDARDUSER],
			});

			setUsers(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param value
	 * @param user
	 */
	function makeEditPasswordCell(value: never, user: User): ReactNode {
		return (
			<ManageUsersEditPasswordCell
				user={user}
				onDone={readUsers}
			/>
		);
	}

	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param value
	 * @param user
	 */
	function makeToggleEnableCell(value: never, user: User): ReactNode {
		return (
			<ManageUsersToggleEnableCell
				user={user}
				onDone={readUsers}
			/>
		);
	}

	return (
		<div>
			<AddUserModal
				isOpen={showNewUserModal}
				onClose={toggleNewUserModal}
				onDone={onDoneNewUserModal}
			/>

			<PageHeader>
				<div>
					<h3>
						Manage Users
					</h3>

					<p>
						On this page you can see a list of all the current users on the platform, edit their
						passwords, and enable/disable them. Additionally, you can add a new user account with the
						button below.
					</p>

					{/*<Button*/}
					{/*	color="darkPurple"*/}
					{/*	onClick={toggleNewUserModal}*/}
					{/*>*/}
					{/*	Add New User*/}
					{/*</Button>*/}
				</div>
			</PageHeader>

			<div className="p-3">
				<FrameOneTableContainer
					data={users?.users}
					pagination={{
						...users?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "email",
							headerValue: "Email",
							showSortIcons: false,
							sortable: true,
						},
						{
							key: "disabled",
							headerValue: "Enabled",
							showSortIcons: false,
							sortable: true,
							valueFormatter: convertUserDisabledStatusToEnabledString,
						},
						{
							key: undefined,
							headerValue: "Edit Password",
							showSortIcons: false,
							sortable: false,
							cellRender: makeEditPasswordCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: undefined,
							headerValue: "Enable/Disable Admin",
							showSortIcons: false,
							sortable: false,
							cellRender: makeToggleEnableCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageUsers);
