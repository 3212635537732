"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavoriteUserToJSON = exports.FavoriteUserFromJSONTyped = exports.FavoriteUserFromJSON = void 0;
const _1 = require("./");
function FavoriteUserFromJSON(json) {
    return FavoriteUserFromJSONTyped(json, false);
}
exports.FavoriteUserFromJSON = FavoriteUserFromJSON;
function FavoriteUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.FavoriteFromJSONTyped(json, ignoreDiscriminator),
        'user': _1.StandardUserFromJSON(json['user']),
    };
}
exports.FavoriteUserFromJSONTyped = FavoriteUserFromJSONTyped;
function FavoriteUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.FavoriteToJSON(value),
        'user': _1.StandardUserToJSON(value.user),
    };
}
exports.FavoriteUserToJSON = FavoriteUserToJSON;
