"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetBase64ResponseToJSON = exports.AssetBase64ResponseFromJSONTyped = exports.AssetBase64ResponseFromJSON = void 0;
function AssetBase64ResponseFromJSON(json) {
    return AssetBase64ResponseFromJSONTyped(json, false);
}
exports.AssetBase64ResponseFromJSON = AssetBase64ResponseFromJSON;
function AssetBase64ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': json['data'],
    };
}
exports.AssetBase64ResponseFromJSONTyped = AssetBase64ResponseFromJSONTyped;
function AssetBase64ResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': value.data,
    };
}
exports.AssetBase64ResponseToJSON = AssetBase64ResponseToJSON;
