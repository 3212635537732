"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingToJSON = exports.ListingFromJSONTyped = exports.ListingFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ListingFromJSON(json) {
    return ListingFromJSONTyped(json, false);
}
exports.ListingFromJSON = ListingFromJSON;
function ListingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'seller': _1.StandardUserFromJSON(json['seller']),
        'auditor': !runtime_1.exists(json, 'auditor') ? undefined : _1.AdminFromJSON(json['auditor']),
        'status': _1.ListingStatusFromJSON(json['status']),
        'reason': !runtime_1.exists(json, 'reason') ? undefined : json['reason'],
        'priority': !runtime_1.exists(json, 'priority') ? undefined : json['priority'],
        'name': json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'address': _1.AddressFromJSON(json['address']),
        'distance': !runtime_1.exists(json, 'distance') ? undefined : json['distance'],
        'price': json['price'],
        'quantity': json['quantity'],
        'images': (json['images'].map(_1.AssetFromJSON)),
        'productType': json['productType'],
        'dietaryRestrictions': !runtime_1.exists(json, 'dietaryRestrictions') ? undefined : json['dietaryRestrictions'],
        'placeOfOrigin': !runtime_1.exists(json, 'placeOfOrigin') ? undefined : json['placeOfOrigin'],
        'remainingQuantity': json['remainingQuantity'],
        'avgRating': !runtime_1.exists(json, 'avgRating') ? undefined : json['avgRating'],
        'reviews': (json['reviews'].map(_1.ReviewFromJSON)),
        'expirationDate': !runtime_1.exists(json, 'expirationDate') ? undefined : json['expirationDate'],
        'creationDate': json['creationDate'],
    };
}
exports.ListingFromJSONTyped = ListingFromJSONTyped;
function ListingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'seller': _1.StandardUserToJSON(value.seller),
        'auditor': _1.AdminToJSON(value.auditor),
        'status': _1.ListingStatusToJSON(value.status),
        'reason': value.reason,
        'priority': value.priority,
        'name': value.name,
        'description': value.description,
        'address': _1.AddressToJSON(value.address),
        'distance': value.distance,
        'price': value.price,
        'quantity': value.quantity,
        'images': (value.images.map(_1.AssetToJSON)),
        'productType': value.productType,
        'dietaryRestrictions': value.dietaryRestrictions,
        'placeOfOrigin': value.placeOfOrigin,
        'remainingQuantity': value.remainingQuantity,
        'avgRating': value.avgRating,
        'reviews': (value.reviews.map(_1.ReviewToJSON)),
        'expirationDate': value.expirationDate,
        'creationDate': value.creationDate,
    };
}
exports.ListingToJSON = ListingToJSON;
