"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewToJSON = exports.ReviewFromJSONTyped = exports.ReviewFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function ReviewFromJSON(json) {
    return ReviewFromJSONTyped(json, false);
}
exports.ReviewFromJSON = ReviewFromJSON;
function ReviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'type': _1.ReviewTypeFromJSON(json['type']),
        'edited': json['edited'],
        'rating': json['rating'],
        'message': !runtime_1.exists(json, 'message') ? undefined : json['message'],
    };
}
exports.ReviewFromJSONTyped = ReviewFromJSONTyped;
function ReviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'type': _1.ReviewTypeToJSON(value.type),
        'edited': value.edited,
        'rating': value.rating,
        'message': value.message,
    };
}
exports.ReviewToJSON = ReviewToJSON;
