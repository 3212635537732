"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeToJSON = exports.UserTypeFromJSONTyped = exports.UserTypeFromJSON = exports.UserType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var UserType;
(function (UserType) {
    UserType["GODADMIN"] = "GOD_ADMIN";
    UserType["STANDARDADMIN"] = "STANDARD_ADMIN";
    UserType["STANDARDUSER"] = "STANDARD_USER";
})(UserType = exports.UserType || (exports.UserType = {}));
function UserTypeFromJSON(json) {
    return UserTypeFromJSONTyped(json, false);
}
exports.UserTypeFromJSON = UserTypeFromJSON;
function UserTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.UserTypeFromJSONTyped = UserTypeFromJSONTyped;
function UserTypeToJSON(value) {
    return value;
}
exports.UserTypeToJSON = UserTypeToJSON;
