import React, {ReactNode} from "react";
import ProfilePicture from "./assetManager/ProfilePicture";

interface IProps {
	src?: string;
	alt?: string;
	onClick?: () => void;
	labels: string[];
}

const ProfilePictureVerticalLabel: React.FC<IProps> = (props) => {

	function makeLabel(label: string, i: number): ReactNode {
		return (
			<p
				key={`profile-picture-vertical_${label}_${i}_${Math.random()}`}
				className="profile-picture-vertical_label"
			>
				{label}
			</p>
		);
	}

	return (
		<div className="profile-picture-vertical">
			<ProfilePicture
				src={props.src}
				alt={props.alt}
				onClick={props.onClick}
				className="profile-picture-vertical_image-container"
			/>

			{props.labels?.map(makeLabel)}
		</div>
	);
};

ProfilePictureVerticalLabel.defaultProps = {
	labels: [],
};

export default ProfilePictureVerticalLabel;
