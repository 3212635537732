"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtilsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class UtilsApi extends runtime.BaseAPI {
    /**
     */
    async getListOfDialCodesRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/utils/get-dial-codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.DialCodeFromJSON));
    }
    /**
     */
    async getListOfDialCodes() {
        const response = await this.getListOfDialCodesRaw();
        return await response.value();
    }
    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async healthRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Should always return a status code of 200. Used to determine if the server is up and working.
     */
    async health() {
        await this.healthRaw();
    }
    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async initRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * A safe public API to initialize the server. This API is sustainable to even if compromised, it will not cause problems.
     */
    async init() {
        await this.initRaw();
    }
    /**
     */
    async sandboxRaw(requestParameters) {
        const queryParameters = {};
        if (requestParameters.lng !== undefined) {
            queryParameters['lng'] = requestParameters.lng;
        }
        if (requestParameters.lat !== undefined) {
            queryParameters['lat'] = requestParameters.lat;
        }
        if (requestParameters.radius !== undefined) {
            queryParameters['radius'] = requestParameters.radius;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/sandbox`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.SandboxResponseFromJSON(jsonValue));
    }
    /**
     */
    async sandbox(requestParameters) {
        const response = await this.sandboxRaw(requestParameters);
        return await response.value();
    }
    /**
     * Finds what version of the app you are using
     */
    async versionRaw() {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.VersionResponseFromJSON(jsonValue));
    }
    /**
     * Finds what version of the app you are using
     */
    async version() {
        const response = await this.versionRaw();
        return await response.value();
    }
}
exports.UtilsApi = UtilsApi;
