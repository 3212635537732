"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardAdminToJSON = exports.StandardAdminFromJSONTyped = exports.StandardAdminFromJSON = void 0;
function StandardAdminFromJSON(json) {
    return StandardAdminFromJSONTyped(json, false);
}
exports.StandardAdminFromJSON = StandardAdminFromJSON;
function StandardAdminFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StandardAdminFromJSONTyped = StandardAdminFromJSONTyped;
function StandardAdminToJSON(value) {
    return value;
}
exports.StandardAdminToJSON = StandardAdminToJSON;
