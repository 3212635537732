"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetInvestorsResponseToJSON = exports.GetInvestorsResponseFromJSONTyped = exports.GetInvestorsResponseFromJSON = void 0;
const _1 = require("./");
function GetInvestorsResponseFromJSON(json) {
    return GetInvestorsResponseFromJSONTyped(json, false);
}
exports.GetInvestorsResponseFromJSON = GetInvestorsResponseFromJSON;
function GetInvestorsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'investors': (json['investors'].map(_1.InvestorFromJSON)),
    };
}
exports.GetInvestorsResponseFromJSONTyped = GetInvestorsResponseFromJSONTyped;
function GetInvestorsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'investors': (value.investors.map(_1.InvestorToJSON)),
    };
}
exports.GetInvestorsResponseToJSON = GetInvestorsResponseToJSON;
