"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterUserBodyToJSON = exports.RegisterUserBodyFromJSONTyped = exports.RegisterUserBodyFromJSON = void 0;
const _1 = require("./");
function RegisterUserBodyFromJSON(json) {
    return RegisterUserBodyFromJSONTyped(json, false);
}
exports.RegisterUserBodyFromJSON = RegisterUserBodyFromJSON;
function RegisterUserBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountInformation': _1.AccountInformationFromJSON(json['accountInformation']),
        'phoneNumberVerificationID': json['phoneNumberVerificationID'],
        'phoneNumber': _1.PhoneNumberBodyFromJSON(json['phoneNumber']),
        'verificationCode': json['verificationCode'],
    };
}
exports.RegisterUserBodyFromJSONTyped = RegisterUserBodyFromJSONTyped;
function RegisterUserBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountInformation': _1.AccountInformationToJSON(value.accountInformation),
        'phoneNumberVerificationID': value.phoneNumberVerificationID,
        'phoneNumber': _1.PhoneNumberBodyToJSON(value.phoneNumber),
        'verificationCode': value.verificationCode,
    };
}
exports.RegisterUserBodyToJSON = RegisterUserBodyToJSON;
