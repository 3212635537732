import React, {ReactNode} from "react";
import {FiChevronRight} from "react-icons/all";
import classNames from "classnames";
import { Link } from "react-router-dom";

export interface IBreadCrumbItem {
	label: string;
	route: string;
}

interface IProps {
	crumbs: Array<IBreadCrumbItem>;
}

const BreadCrumbs: React.FC<IProps> = (props) => {

	function makeCrumb(item: IBreadCrumbItem, i: number): ReactNode {
		return (
			<div
				key={`bread-crumb-item_${item.label}_${i}`}
				className="bread-crumb-container_item"
			>
				{i > 0 && (
					<FiChevronRight className="bread-crumb-container_item_icon"/>
				)}

				<Link
					className={classNames("bread-crumb-container_item_link")}
					to={item.route}
				>
					{item.label}
				</Link>
			</div>
		);
	}

	return (
		<div className="bread-crumb-container">
			{props.crumbs?.map(makeCrumb)}
		</div>
	);
};

BreadCrumbs.defaultProps = {
	crumbs: [],
};

export default BreadCrumbs;
