"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewTypeToJSON = exports.ViewTypeFromJSONTyped = exports.ViewTypeFromJSON = exports.ViewType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ViewType;
(function (ViewType) {
    ViewType["USER"] = "USER";
    ViewType["LISTING"] = "LISTING";
})(ViewType = exports.ViewType || (exports.ViewType = {}));
function ViewTypeFromJSON(json) {
    return ViewTypeFromJSONTyped(json, false);
}
exports.ViewTypeFromJSON = ViewTypeFromJSON;
function ViewTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ViewTypeFromJSONTyped = ViewTypeFromJSONTyped;
function ViewTypeToJSON(value) {
    return value;
}
exports.ViewTypeToJSON = ViewTypeToJSON;
