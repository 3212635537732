import React, {ReactNode, useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {FaRegCopy, GoCheck} from "react-icons/all";
import {Investor} from "client";

interface IProps {
	investor: Investor;
}

const ManageInvestorsCopyLinkCell: React.FC<IProps> = (props) => {

	const [showCheck, setShowCheck] = useState(false);

	/**
	 * Copy the code to the clipboard.
	 *
	 */
	async function copyLink(): Promise<void> {
		try {
			await navigator.clipboard.writeText(process.env.REACT_APP_WEB_URL + `/investor/${props.investor?.code}`);

			setShowCheck(true);
			setTimeout(() => setShowCheck(false), 2000);
		} catch (e){

		}
	}

	/**
	 * Dynamically render the button's icon.
	 *
	 */
	function getIcon(): ReactNode {
		return showCheck ? GoCheck : FaRegCopy;
	}

	return (
		<div className="frame-one-table-cell">
			<FrameButton
				icon={getIcon()}
				color="darkPurple"
				outline={true}
				onClick={copyLink}
			>
				Copy Link
			</FrameButton>
		</div>
	);
};

export default ManageInvestorsCopyLinkCell;
