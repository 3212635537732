import React from "react";
import {connect} from "react-redux";
import { IStore } from "../../redux/defaultStore";
import {Investor, InvestorsApi, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	investor: Investor;
	onClose: () => void;
	onDone: () => void;
}

const ManageInvestorsDeleteModal: React.FC<IProps> = (props) => {

	/**
	 * Call the api to delete the selected investor,
	 * then call the props.onDone function to hide this modal
	 * and fetch new data for the table.
	 *
	 */
	async function confirmDelete(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new InvestorsApi(getConfig(props.fullToken)).deleteInvestor({
				id: props.investor?._id,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
	    >
			<FrameModalHeader
				title="Delete Investor"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to delete <b>{props.investor?.name}</b> with wallet address <b>{props.investor?.walletAddress}</b>?
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="red"
					onClick={confirmDelete}
				>
					Confirm, Delete
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageInvestorsDeleteModal);
