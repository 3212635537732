"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseToJSON = exports.BaseFromJSONTyped = exports.BaseFromJSON = void 0;
const runtime_1 = require("../runtime");
function BaseFromJSON(json) {
    return BaseFromJSONTyped(json, false);
}
exports.BaseFromJSON = BaseFromJSON;
function BaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        '__v': !runtime_1.exists(json, '__v') ? undefined : json['__v'],
    };
}
exports.BaseFromJSONTyped = BaseFromJSONTyped;
function BaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        '__v': value.__v,
    };
}
exports.BaseToJSON = BaseToJSON;
