"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderToJSON = exports.OrderFromJSONTyped = exports.OrderFromJSON = void 0;
const _1 = require("./");
function OrderFromJSON(json) {
    return OrderFromJSONTyped(json, false);
}
exports.OrderFromJSON = OrderFromJSON;
function OrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'status': _1.OrderStatusFromJSON(json['status']),
        'user': _1.StandardUserFromJSON(json['user']),
        'listing': _1.ListingFromJSON(json['listing']),
        'quantity': json['quantity'],
        'price': json['price'],
    };
}
exports.OrderFromJSONTyped = OrderFromJSONTyped;
function OrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'status': _1.OrderStatusToJSON(value.status),
        'user': _1.StandardUserToJSON(value.user),
        'listing': _1.ListingToJSON(value.listing),
        'quantity': value.quantity,
        'price': value.price,
    };
}
exports.OrderToJSON = OrderToJSON;
