"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewTypeToJSON = exports.ReviewTypeFromJSONTyped = exports.ReviewTypeFromJSON = exports.ReviewType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ReviewType;
(function (ReviewType) {
    ReviewType["LISTING"] = "LISTING";
    ReviewType["USER"] = "USER";
})(ReviewType = exports.ReviewType || (exports.ReviewType = {}));
function ReviewTypeFromJSON(json) {
    return ReviewTypeFromJSONTyped(json, false);
}
exports.ReviewTypeFromJSON = ReviewTypeFromJSON;
function ReviewTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ReviewTypeFromJSONTyped = ReviewTypeFromJSONTyped;
function ReviewTypeToJSON(value) {
    return value;
}
exports.ReviewTypeToJSON = ReviewTypeToJSON;
