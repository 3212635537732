"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetReviewsResponseToJSON = exports.GetReviewsResponseFromJSONTyped = exports.GetReviewsResponseFromJSON = void 0;
const _1 = require("./");
function GetReviewsResponseFromJSON(json) {
    return GetReviewsResponseFromJSONTyped(json, false);
}
exports.GetReviewsResponseFromJSON = GetReviewsResponseFromJSON;
function GetReviewsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'reviews': (json['reviews'].map(_1.ReviewFromJSON)),
    };
}
exports.GetReviewsResponseFromJSONTyped = GetReviewsResponseFromJSONTyped;
function GetReviewsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'reviews': (value.reviews.map(_1.ReviewToJSON)),
    };
}
exports.GetReviewsResponseToJSON = GetReviewsResponseToJSON;
