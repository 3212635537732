import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Investor, InvestorBody, InvestorsApi, Token} from "client";
import {defaultCreateInvestorForm} from "./CreateInvestorModal";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {Input, Label} from "reactstrap";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameModal from "./modalComponents/FrameModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	investor: Investor;
	onClose: () => void;
	onDone: () => void;
}

const ManageInvestorsEditInvestorModal: React.FC<IProps> = (props) => {

	const [editForm, setEditForm] = useState<InvestorBody>(defaultCreateInvestorForm);

	/**
	 * Listen for when the modal opens,
	 * and update the edit form to use the
	 * existing values on the investor.
	 *
	 */
	useEffect(() => {
		if (props.isOpen) {
			resetForm();
		}
	}, [props.isOpen]);

	/**
	 * Resets the form to the initial values on the investor.
	 *
	 */
	function resetForm(): void {
		setEditForm({
			name: props.investor?.name,
			walletAddress: props.investor?.walletAddress,
			tokens: props.investor?.tokens,
		});
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		resetForm();
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof InvestorBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEditForm({
				...editForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Dynamic onChange for the number format inputs.
	 *
	 * @param key
	 */
	function numberInputOnChange(key: keyof InvestorBody): (values: NumberFormatValues) => void {
		return (values) => {
			setEditForm({
				...editForm,
				[key]: values.floatValue,
			});
		}
	}

	/**
	 * Call api to update the investor,
	 * then close the modal afterwards.
	 *
	 * @param e
	 */
	async function submitUpdateInvestor(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new InvestorsApi(getConfig(props.fullToken)).updateInvestor({
				id: props.investor?._id,
				investorBody: {
					name: editForm?.name || undefined,
					walletAddress: editForm?.walletAddress || undefined,
					tokens: editForm?.tokens || undefined,
				},
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="md"
		>
			<FrameModalHeader
				title="Add Investor"
				toggle={closeHelper}
			/>

			<form onSubmit={submitUpdateInvestor}>
				<FrameModalBody>
					<Label>
						Investor Name*
					</Label>
					<Input
						placeholder="Investor Name..."
						value={editForm?.name}
						onChange={dynamicOnChange("name")}
						className="mb-3"
					/>

					<Label>
						Wallet Address*
					</Label>
					<Input
						placeholder="Wallet Address..."
						value={editForm?.walletAddress}
						onChange={dynamicOnChange("walletAddress")}
						className="mb-3"
					/>

					<Label>
						Tokens*
					</Label>
					<NumberFormat
						placeholder="Tokens..."
						value={editForm?.tokens}
						customInput={Input}
						allowLeadingZeros={false}
						allowNegative={false}
						decimalScale={0}
						onValueChange={numberInputOnChange("tokens")}
						className="mb-3"
					/>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitUpdateInvestor}
					>
						Update Investor
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageInvestorsEditInvestorModal);
