"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SandboxResponseToJSON = exports.SandboxResponseFromJSONTyped = exports.SandboxResponseFromJSON = void 0;
const _1 = require("./");
function SandboxResponseFromJSON(json) {
    return SandboxResponseFromJSONTyped(json, false);
}
exports.SandboxResponseFromJSON = SandboxResponseFromJSON;
function SandboxResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'addresses': (json['addresses'].map(_1.AddressBookEntryFromJSON)),
    };
}
exports.SandboxResponseFromJSONTyped = SandboxResponseFromJSONTyped;
function SandboxResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'addresses': (value.addresses.map(_1.AddressBookEntryToJSON)),
    };
}
exports.SandboxResponseToJSON = SandboxResponseToJSON;
