import React, {ChangeEventHandler, useState} from "react";
import {Admin, ChangeUserPasswordBody, Token, UsersApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	admin: Admin;
	onClose: () => void;
	onDone: () => void;
}

type ChangeUserPasswordBodyFrontend = Pick<ChangeUserPasswordBody, "password" | "confirmPassword">;

const defaultUpdateAdminPasswordForm: ChangeUserPasswordBodyFrontend = {
	password: "",
	confirmPassword: "",
};

const ManageAdminsUpdatePasswordModal: React.FC<IProps> = (props) => {

	const [form, setForm] = useState<ChangeUserPasswordBodyFrontend>(defaultUpdateAdminPasswordForm);

	/**
	 * Reset form & close modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultUpdateAdminPasswordForm);
		props.onClose();
	}

	/**
	 * Dynamic on change for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof ChangeUserPasswordBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call api to update password,
	 * then reset the form & close the modal.
	 *
	 */
	async function submitUpdateAdminPassword(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).changeUserPassword({
				id: props.admin?._id,
				changeUserPasswordBody: {
					password: form.password,
					confirmPassword: form.confirmPassword,
				},
			});

			setForm(defaultUpdateAdminPasswordForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Update Admin Password"
			/>

			<form onSubmit={submitUpdateAdminPassword}>
				<FrameModalBody>
					<div className="mb-3">
						<Label>
							New Password*
						</Label>
						<Input
							type="password"
							value={form.password}
							placeholder="Password"
							onChange={createOnChange("password")}
						/>
					</div>

					<div>
						<Label>
							Confirm New Password*
						</Label>
						<Input type="password" value={form.confirmPassword} placeholder="Confirm Password"
						       onChange={createOnChange("confirmPassword")}/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="darkPurple"
						onClick={submitUpdateAdminPassword}
						type="submit"
					>
						Update Password
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageAdminsUpdatePasswordModal)
