"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavoriteToJSON = exports.FavoriteFromJSONTyped = exports.FavoriteFromJSON = void 0;
const _1 = require("./");
const _2 = require("./");
function FavoriteFromJSON(json) {
    return FavoriteFromJSONTyped(json, false);
}
exports.FavoriteFromJSON = FavoriteFromJSON;
function FavoriteFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'LISTING') {
            return _2.FavoriteListingFromJSONTyped(json, true);
        }
        if (json['type'] === 'USER') {
            return _2.FavoriteUserFromJSONTyped(json, true);
        }
    }
    return {
        '_id': json['_id'],
        'type': _1.FavoriteTypeFromJSON(json['type']),
    };
}
exports.FavoriteFromJSONTyped = FavoriteFromJSONTyped;
function FavoriteToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'type': _1.FavoriteTypeToJSON(value.type),
    };
}
exports.FavoriteToJSON = FavoriteToJSON;
