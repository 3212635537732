"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavoriteListingToJSON = exports.FavoriteListingFromJSONTyped = exports.FavoriteListingFromJSON = void 0;
const _1 = require("./");
function FavoriteListingFromJSON(json) {
    return FavoriteListingFromJSONTyped(json, false);
}
exports.FavoriteListingFromJSON = FavoriteListingFromJSON;
function FavoriteListingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.FavoriteFromJSONTyped(json, ignoreDiscriminator),
        'listing': _1.ListingFromJSON(json['listing']),
    };
}
exports.FavoriteListingFromJSONTyped = FavoriteListingFromJSONTyped;
function FavoriteListingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.FavoriteToJSON(value),
        'listing': _1.ListingToJSON(value.listing),
    };
}
exports.FavoriteListingToJSON = FavoriteListingToJSON;
