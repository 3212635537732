import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {RegisterUserBody, Token, UsersApi} from "client";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

// TODO
// const defaultAddUserForm: RegisterUserBody = {
const defaultAddUserForm: any = {
	email: "",
	password: "",
	confirmPassword: "",
};

const AddUserModal: React.FC<IProps> = (props: IProps) => {

	const [form, setForm] = useState<RegisterUserBody>(defaultAddUserForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultAddUserForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof RegisterUserBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call api to create new user,
	 * reset form & close modal on success.
	 *
	 */
	async function submitAddNewUser(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig()).registerUser({registerUserBody: form});
			props.dispatch(decrementLoading());
			setForm(defaultAddUserForm);
			props.onDone();
		} catch (e) {
			props.dispatch(decrementLoading());
			props.dispatch(addError(e));
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
			size="md"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New User"
			/>

			<form onSubmit={submitAddNewUser}>
				<FrameModalBody>
					{/*<div className="mb-3">*/}
					{/*	<Label>*/}
					{/*		Email**/}
					{/*	</Label>*/}
					{/*	<Input*/}
					{/*		value={form.email}*/}
					{/*		placeholder="Email"*/}
					{/*		onChange={createOnChange("email")}*/}
					{/*	/>*/}
					{/*</div>*/}

					{/*<div className="mb-3">*/}
					{/*	<Label>*/}
					{/*		Password**/}
					{/*	</Label>*/}
					{/*	<Input*/}
					{/*		type="password"*/}
					{/*		value={form.password}*/}
					{/*		placeholder="Password"*/}
					{/*		onChange={createOnChange("password")}*/}
					{/*	/>*/}
					{/*</div>*/}

					{/*<div>*/}
					{/*	<Label>*/}
					{/*		Confirm Password**/}
					{/*	</Label>*/}
					{/*	<Input*/}
					{/*		type="password"*/}
					{/*		value={form.confirmPassword}*/}
					{/*		placeholder="Confirm Password"*/}
					{/*		onChange={createOnChange("confirmPassword")}*/}
					{/*	/>*/}
					{/*</div>*/}
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="darkPurple"
						onClick={submitAddNewUser}
						type="submit"
					>
						Add User
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(AddUserModal);
