import React, {useState} from "react";
import {Col, Input, Label, Row} from "reactstrap";
import FrameButton from "../components/buttons/FrameButton";
import {FiClipboard, FiPlus, FiUsers, FiWatch} from "react-icons/all";
import LabelOutline from "../components/LabelOutline";
import BreadCrumbs from "../components/BreadCrumbs";
import CompanyPhoto from "../components/CompanyPhoto";
import ProfilePicture from "../components/assetManager/ProfilePicture";
import ProfilePictureVerticalLabel from "../components/ProfilePictureVerticalLabel";
import IconButton from "../components/buttons/IconButton";
import IconButtonMini from "../components/buttons/IconButtonMini";
import CompanyCard from "../components/CompanyCard";
import FrameModalHeader from "../components/modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../components/modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../components/modals/modalComponents/FrameModalFooter";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import FrameOneCard from "../components/FrameOneCard";
import FrameOneRoundPanel from "../components/FrameOneRoundPanel";
import SectionLabel from "../components/SectionLabel";
import FrameOneCheckbox from "../components/FrameOneCheckbox";
import FrameOneRadio from "../components/FrameOneRadio";
import FrameOneToggle from "../components/FrameOneToggle";
import FrameOneButtonActions from "../components/FrameOneButtonActions";
import PageHeader from "../components/PageHeader";
import FrameOneContainer from "../components/FrameOneContainer";

interface IProps {

}

const columnClassName: string = "col col-12 col-sm-12 col-md-6 col-xxl-4 mb-5";

const ComponentDemoPage: React.FC<IProps> = (props) => {

	const [checkbox1Checked, setCheckbox1Checked] = useState(false);
	const [radioVal, setRadioVal] = useState<"one" | "two" | "three">(undefined);
	const [toggleStatus, setToggleStatus] = useState(false);

	function toggleCheckbox(): void {
		setCheckbox1Checked(!checkbox1Checked);
	}

	function setRadioValHelper(val: "one" | "two" | "three"): void {
		if (val === radioVal) {
			setRadioVal(undefined);
		} else {
			setRadioVal(val);
		}
	}

	function onTogglerChange(): void {
		setToggleStatus(!toggleStatus);
	}

	return (
		<div>
			<PageHeader>
				<h3 className="mb-0">
					Component Demo Page
				</h3>
			</PageHeader>

			<FrameOneContainer className="demo-page">
				<div>
					<h4 className="d-sm-none">
						extra small
					</h4>

					<h4 className="d-none d-sm-block d-md-none">
						small
					</h4>

					<h4 className="d-none d-md-block d-lg-none">
						medium
					</h4>

					<h4 className="d-none d-lg-block d-xl-none">
						large
					</h4>

					<h4 className="d-none d-xl-block d-xxl-none">
						xl
					</h4>

					<h4 className="d-none d-xxl-block d-xxxl-none">
						xxl
					</h4>

					<h4 className="d-none d-xxxl-block">
						xxxl
					</h4>

					{/*<h4 className="d-none d-sb-sm-block d-sb-md-none">*/}
					{/*	sb-sm*/}
					{/*</h4>*/}

					{/*<h4 className="d-none d-sb-md-block d-sb-lg-none">*/}
					{/*	sb-md*/}
					{/*</h4>*/}

					{/*<h4 className="d-none d-sb-lg-block">*/}
					{/*	sb-lg*/}
					{/*</h4>*/}

					{/*<h4 className="d-none d-xl-block d-xxl-none">*/}
					{/*	extra large*/}
					{/*</h4>*/}

					{/*<h4 className="d-none d-xxl-block">*/}
					{/*	extra extra large*/}
					{/*</h4>*/}
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Typography
					</h4>
					<div className="row">
						{/*<div className={columnClassName}>*/}
						<div className={columnClassName}>
							<h1 className="mb-0">
								Aa
							</h1>
							<p>
								Miriam Libre
							</p>
						</div>

						<div className={columnClassName}>
							<h1>Heading 1</h1>
							<h2>Heading 2</h2>
							<h3>Heading 3</h3>
							<h4>Heading 4</h4>
							<h5>Heading 5</h5>
							<h6>Heading 6</h6>
						</div>

						<div className={columnClassName}>
							<p>Paragraph</p>

							<Label>Label</Label>
						</div>
					</div>
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Buttons
					</h4>
					<div className="row">
						<div className={columnClassName}>
							<h6>Button 1</h6>
							<hr/>
							<FrameButton color="darkPurple">
								Button Label 1
							</FrameButton>
						</div>

						<div className={columnClassName}>
							<h6>Button 2</h6>
							<hr/>
							<FrameButton color="darkBlue">
								Button Label 2
							</FrameButton>
						</div>

						<div className={columnClassName}>
							<h6>Icon Button</h6>
							<hr/>
							<FrameButton color="darkPurple" icon={FiWatch}>
								Icon Button
							</FrameButton>
						</div>

						<div className={columnClassName}>
							<h6>Line Button 1</h6>
							<hr/>
							<FrameButton color="darkPurple" outline={true}>
								Line Button 1
							</FrameButton>
						</div>

						<div className={columnClassName}>
							<h6>Line Button 2</h6>
							<hr/>
							<FrameButton color="darkBlue" outline={true}>
								Line Button 2
							</FrameButton>
						</div>

						<div className={columnClassName}>
							<h6>Text Button</h6>
							<hr/>
							<FrameButton color="link" className="text-darkBlue">
								Text Button
							</FrameButton>
						</div>
					</div>
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Forms & Inputs
					</h4>
					<div className="row">
						<div className={columnClassName}>
							<h6>Input Field</h6>
							<hr/>
							<Label>Standard</Label>
							<Input placeholder="Placeholder Text..." className="mb-3"/>

							<Label>Disabled</Label>
							<Input placeholder="Disabled Placeholder..." disabled={true} className="mb-5"/>

							<h6>Dropdown</h6>
							<hr/>
							<div className="mb-3">
								<Label>Standard</Label>
								<Input type="select">
									<option selected disabled>Select a choice</option>
									<hr/>
									<option>Option 1</option>
									<option>Option 2</option>
									<option>Option 3</option>
								</Input>
							</div>

							<div>
								<Label>Disabled</Label>
								<Input type="select" disabled={true}>
									<option selected disabled>Select a choice</option>
									<hr/>
									<option>Option 1</option>
									<option>Option 2</option>
									<option>Option 3</option>
								</Input>
							</div>
						</div>

						<div className={columnClassName}>
							<h6>Text Box</h6>
							<hr/>
							<Label>Standard</Label>
							<Input placeholder="Placeholder Text..." type="textarea" className="mb-3"/>

							<Label>Disabled</Label>
							<Input placeholder="Placeholder Text..." type="textarea" disabled={true}/>
						</div>

						<div className={columnClassName}>
							<h6>Checkbox</h6>
							<hr/>

							<div className="mb-5">
								<div className="mb-3">
									<FrameOneCheckbox
										checked={checkbox1Checked}
										onToggle={toggleCheckbox}
									>
										This is a Checkbox
									</FrameOneCheckbox>
								</div>

								<div>
									<FrameOneCheckbox
										checked={false}
										onToggle={() => {
										}}
										disabled={true}
									>
										This is a disabled Checkbox
									</FrameOneCheckbox>
								</div>
							</div>

							<h6>Radio Button</h6>
							<hr/>

							<div>
								<div className="mb-3">
									<FrameOneRadio
										onToggle={setRadioValHelper}
										name="demoPageTest1"
										value="one"
										checked={radioVal === "one"}
									>
										This is a radio button 1
									</FrameOneRadio>
								</div>

								<div className="mb-3">
									<FrameOneRadio
										onToggle={setRadioValHelper}
										name="demoPageTest1"
										value="two"
										checked={radioVal === "two"}
									>
										This is a radio button 2
									</FrameOneRadio>
								</div>

								<div className="mb-3">
									<FrameOneRadio
										onToggle={setRadioValHelper}
										name="demoPageTest1"
										value="three"
										checked={radioVal === "three"}
										disabled={true}
									>
										This is a disabled radio button
									</FrameOneRadio>
								</div>
							</div>
						</div>

						<div className={columnClassName}>
						</div>
					</div>
				</div>

				<hr/>

				<div className="demo-page_misc">
					<h4 className="mb-3">
						Misc.
					</h4>
					<div className="row">
						<div className={columnClassName}>
							<h6>Paginator</h6>
							<hr/>
							<p className="font-italic">
								See the "Manage Admins" page.
							</p>
						</div>

						<div className={columnClassName}>
							<h6>Misc. Buttons</h6>
							<hr/>
							<div className="mb-3">
								<IconButton onClick={() => alert("test")}/>
							</div>

							<div>
								<IconButtonMini
									icon={FiPlus}
									onClick={() => alert("test")}
								/>
							</div>
						</div>

						<div className={columnClassName}>
							<h6>Slider</h6>
							<hr/>
							<p>
								TODO ;)
							</p>
						</div>

						<div className={columnClassName}>
							<h6>Toggle Switch</h6>
							<hr/>

							<div>
								<FrameOneToggle
									name="checkTest"
									checked={toggleStatus}
									onChange={onTogglerChange}
								>
									This is our testing toggle button
								</FrameOneToggle>
							</div>
						</div>

						<div className={columnClassName}>
							<h6>Navigation</h6>
							<hr/>
							<BreadCrumbs
								crumbs={[
									{label: "Dashboard", route: "/dashboard"},
									{label: "Admins", route: "/manage-admins"},
									{label: "Demo Page", route: "/demo"},
								]}
							/>
						</div>

						<div className={columnClassName}>
							<h6>Tags</h6>
							<hr/>
							<div className="demo-page_misc_tags-container">
								<LabelOutline>
									Basic Label
								</LabelOutline>

								<LabelOutline onClick={() => alert("test")}>
									Label with an onClick
								</LabelOutline>

								<LabelOutline className="text-purple border-purple bg-lightGray">
									Label with custom className
								</LabelOutline>
							</div>
						</div>

						<div className={columnClassName}>
							<h6>Profile Picture</h6>
							<hr/>
							<div className="d-flex flex-column align-items-center">
								<div className="w-50 mb-3">
									<ProfilePicture/>
								</div>

								<div className="w-50 mb-3">
									<ProfilePicture
										src={process.env.PUBLIC_URL + "/images/pirate-test.png"}
										onClick={() => alert("test")}
									/>
								</div>

								<div className="w-50 mb-3">
									<ProfilePictureVerticalLabel
										labels={["Spencer Gray", "Admin"]}
									/>
								</div>

								<div className="w-50 mb-3">
									<ProfilePictureVerticalLabel
										src={process.env.PUBLIC_URL + "/images/pirate-test.png"}
										onClick={() => alert("test")}
										labels={["Spencer Gray", "Admin"]}
									/>
								</div>
							</div>
						</div>

						<div className={columnClassName}>
							<h6>Company Photo</h6>
							<hr/>
							<div className="d-flex flex-column align-items-center">
								<div className="w-50 mb-3">
									<CompanyPhoto/>
								</div>

								<div className="w-50">
									<CompanyPhoto
										src={process.env.PUBLIC_URL + "/images/pirate-test.png"}
										onClick={() => alert("test")}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Cards
					</h4>
					<div className="row">
						<div className={columnClassName}>
							<h6>Partial Card</h6>
							<hr/>
							<CompanyCard
								src={process.env.PUBLIC_URL + "/images/pirate-test.png"}
								title="Here be Pirates"
							/>
						</div>

						<div className={columnClassName}>
							<h6>Full Card</h6>
							<hr/>
							<CompanyCard
								src={process.env.PUBLIC_URL + "/images/pirate-test.png"}
								title="Here be Pirates"
								onClick={() => alert("test")}
								metrics={[
									{
										icon: FiClipboard,
										label: "3 Projects",
									},
									{
										icon: FiUsers,
										label: "46 Users",
									},
								]}
							/>
						</div>
					</div>
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Cards
					</h4>
					<div className="row">
						<div className="col col-12 mb-5">
							<FrameOneCard>
								<SectionLabel title="This is a Label">
									<div>
										<Label>Field</Label>
										<Input placeholder="Field..."/>
									</div>
								</SectionLabel>

								<hr/>

								<FrameOneButtonActions>
									<FrameButton color="purple">
										These are
									</FrameButton>

									<FrameButton color="purple">
										Buttons at the
									</FrameButton>

									<FrameButton color="purple">
										End of a form
									</FrameButton>
								</FrameOneButtonActions>
							</FrameOneCard>
						</div>

						<div className="col col-12 col-md-6">
							<FrameOneCard>
								<p className="mb-0">This is the normal card!</p>
							</FrameOneCard>
						</div>

						<div className="col col-12 col-md-6">
							<FrameOneRoundPanel className="p-4">
								<p className="mb-0">This is the card from the login screen!</p>
							</FrameOneRoundPanel>
						</div>
					</div>
				</div>

				<hr/>

				<div>
					<h4 className="mb-3">
						Modals
					</h4>
					<div className="row">
						<div className="col col-12 col-xl-6 mb-5">
							<h6>Simple Modal</h6>
							<hr/>
							<div className="border frame-shadow">
								<FrameModalHeader
									title="Modal Title"
									toggle={() => alert("test toggle")}
								/>
								<FrameModalBody>
									<p className="mb-0">
										Lorem Ipsum, and then some.
									</p>
								</FrameModalBody>
								<FrameModalFooter>
									<FrameButton
										color="darkPurple"
										onClick={() => alert("test")}
									>
										Confirm
									</FrameButton>
								</FrameModalFooter>
							</div>
						</div>

						<div className="col col-12">
							<h6>Complex Modal</h6>
							<hr/>
							<div className="border frame-shadow">
								<FrameModalHeader
									title="Add / Remove User"
									toggle={() => alert("test toggle")}
								/>
								<FrameModalBody>
									<FrameOneTableContainer
										data={[
											{
												name: "FirstName LastName 1",
												city: "Vancouver, BC",
												userType: "Standard",
												colors: ["red", "green"],
											},
											{
												name: "FirstName LastName 2",
												city: "Delta, BC",
												userType: "Standard",
												colors: ["Blue", "yellow"],
											},
											{
												name: "FirstName LastName 3",
												city: "Surrey, BC",
												userType: "Admin",
												colors: ["Purple", "orange"],
											},
										]}
										columnOptions={[
											{
												key: "name",
												headerValue: "User Name",
												showSortIcons: false,
												sortable: true,
											},
											{
												key: "city",
												headerValue: "City",
												showSortIcons: false,
												sortable: true,
											},
											{
												key: "userType",
												headerValue: "User Type",
												showSortIcons: false,
												sortable: true,
											},
											{
												key: "colors",
												headerValue: "Colors",
												showSortIcons: false,
												sortable: false,
												cellRender: (value: string, row) => {
													return row?.["colors"]?.map((color: string, i: number) => {
														return (
															<div
																key={`demo-page-complex-modal-table-color_${color}_${i}`}
																className="demo-page_modal-table-color-container"
															>
																<LabelOutline>
																	{color}
																</LabelOutline>
															</div>
														);
													});
												},
											},
										]}
									/>
								</FrameModalBody>
								<FrameModalFooter>
									<FrameButton
										color="darkPurple"
										onClick={() => alert("test")}
									>
										Confirm
									</FrameButton>
								</FrameModalFooter>
							</div>
						</div>
					</div>
				</div>
			</FrameOneContainer>
		</div>
	);
};

export default ComponentDemoPage;
