"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestorInfoToJSON = exports.InvestorInfoFromJSONTyped = exports.InvestorInfoFromJSON = void 0;
function InvestorInfoFromJSON(json) {
    return InvestorInfoFromJSONTyped(json, false);
}
exports.InvestorInfoFromJSON = InvestorInfoFromJSON;
function InvestorInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'tokens': json['tokens'],
        'walletAddress': json['walletAddress'],
    };
}
exports.InvestorInfoFromJSONTyped = InvestorInfoFromJSONTyped;
function InvestorInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'tokens': value.tokens,
        'walletAddress': value.walletAddress,
    };
}
exports.InvestorInfoToJSON = InvestorInfoToJSON;
