"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendingPeriodToJSON = exports.TrendingPeriodFromJSONTyped = exports.TrendingPeriodFromJSON = exports.TrendingPeriod = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TrendingPeriod;
(function (TrendingPeriod) {
    TrendingPeriod["ALLTIME"] = "ALL_TIME";
    TrendingPeriod["YEAR"] = "YEAR";
    TrendingPeriod["MONTH"] = "MONTH";
    TrendingPeriod["WEEK"] = "WEEK";
    TrendingPeriod["DAY"] = "DAY";
})(TrendingPeriod = exports.TrendingPeriod || (exports.TrendingPeriod = {}));
function TrendingPeriodFromJSON(json) {
    return TrendingPeriodFromJSONTyped(json, false);
}
exports.TrendingPeriodFromJSON = TrendingPeriodFromJSON;
function TrendingPeriodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TrendingPeriodFromJSONTyped = TrendingPeriodFromJSONTyped;
function TrendingPeriodToJSON(value) {
    return value;
}
exports.TrendingPeriodToJSON = TrendingPeriodToJSON;
