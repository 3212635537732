"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class UsersApi extends runtime.BaseAPI {
    /**
     * Used by users to change their passwords.
     */
    async changePasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangePasswordBodyToJSON(requestParameters.changePasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to change their passwords.
     */
    async changePassword(requestParameters) {
        await this.changePasswordRaw(requestParameters);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling changeUserPassword.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/change-password`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters) {
        await this.changeUserPasswordRaw(requestParameters);
    }
    /**
     * Used by admins to disable a user
     */
    async disableUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling disableUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to disable a user
     */
    async disableUser(requestParameters) {
        await this.disableUserRaw(requestParameters);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling enableUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/enable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to enable a user
     */
    async enableUser(requestParameters) {
        await this.enableUserRaw(requestParameters);
    }
    /**
     */
    async getProfileRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async getProfile() {
        const response = await this.getProfileRaw();
        return await response.value();
    }
    /**
     */
    async getUserRaw(requestParameters) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUser.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUserResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUser(requestParameters) {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUsersRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getUsers.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getUsers.');
        }
        const queryParameters = {};
        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetUsersResponseFromJSON(jsonValue));
    }
    /**
     */
    async getUsers(requestParameters) {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async loginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.LoginBodyToJSON(requestParameters.loginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login a user account. Returns a token if successful
     */
    async login(requestParameters) {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to register a user
     */
    async registerUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.RegisterUserBodyToJSON(requestParameters.registerUserBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StandardUserFromJSON(jsonValue));
    }
    /**
     * Used to register a user
     */
    async registerUser(requestParameters) {
        const response = await this.registerUserRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create a phone number verification and send a verification code to a phone, on registration.
     */
    async requestRegistrationVerificationRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/register/request-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.RequestRegistrationVerificationBodyToJSON(requestParameters.requestRegistrationVerificationBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RequestRegistrationVerificationResponseFromJSON(jsonValue));
    }
    /**
     * Used to create a phone number verification and send a verification code to a phone, on registration.
     */
    async requestRegistrationVerification(requestParameters) {
        const response = await this.requestRegistrationVerificationRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfileRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update-profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.UpdateProfileBodyToJSON(requestParameters.updateProfileBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to update their profile.
     */
    async updateProfile(requestParameters) {
        await this.updateProfileRaw(requestParameters);
    }
    /**
     * Used on the first step of user registration to validate the account info
     */
    async validateAccountInformationRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/users/register/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AccountInformationToJSON(requestParameters.accountInformation),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used on the first step of user registration to validate the account info
     */
    async validateAccountInformation(requestParameters) {
        await this.validateAccountInformationRaw(requestParameters);
    }
}
exports.UsersApi = UsersApi;
