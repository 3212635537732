import React, {useState} from "react";
import {Investor} from "client";
import FrameButton from "../../buttons/FrameButton";
import ManageInvestorsEditInvestorModal from "../../modals/ManageInvestorsEditInvestorModal";
import {FiEdit2} from "react-icons/all";

interface IProps {
	investor: Investor;
	onDone: () => Promise<void>;
}

const ManageInvestorsEditCell: React.FC<IProps> = (props) => {

	const [showEditModal, setShowEditModal] = useState(false);

	/**
	 * Show or hide the edit modal.
	 *
	 */
	function toggleShowEditModal(): void {
		setShowEditModal(!showEditModal);
	}

	/**
	 * Hide the edit modal and call the
	 * props.onDone function to update the table.
	 *
	 */
	function onDoneEditingInvestor(): void {
		setShowEditModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageInvestorsEditInvestorModal
				isOpen={showEditModal}
				investor={props.investor}
				onClose={toggleShowEditModal}
				onDone={onDoneEditingInvestor}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					icon={FiEdit2}
					color="darkPurple"
					outline={true}
					onClick={toggleShowEditModal}
				>
					Edit
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default ManageInvestorsEditCell;
