import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/modals/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import SideBarManager from "./components/sideBar/SideBarManager";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import ManageAdmins from "./pages/ManageAdmins";
import ManageUsers from "./pages/ManageUsers";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import GlobalContactModalManager from "./components/modals/GlobalContactModalManager";
import ManageInvestors from "./pages/ManageInvestors";
import {isDev} from "./utils/isDev";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<GlobalContactModalManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						<Route exact path="/" component={Login}/>

						{isDev() && <Route exact path="/demo" component={ComponentDemoPage}/>}

						<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>
						<AuthenticatedRoute exact path="/manage-admins" component={ManageAdmins}/>
						<AuthenticatedRoute exact path="/manage-users" component={ManageUsers}/>
						<AuthenticatedRoute exact path="/manage-investors" component={ManageInvestors}/>

						<Redirect to="/"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
