"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateListingBodyToJSON = exports.CreateListingBodyFromJSONTyped = exports.CreateListingBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function CreateListingBodyFromJSON(json) {
    return CreateListingBodyFromJSONTyped(json, false);
}
exports.CreateListingBodyFromJSON = CreateListingBodyFromJSON;
function CreateListingBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'addressBookEntryID': json['addressBookEntryID'],
        'price': json['price'],
        'quantity': json['quantity'],
        'imageIDs': !runtime_1.exists(json, 'imageIDs') ? undefined : json['imageIDs'],
        'productType': json['productType'],
        'dietaryRestrictions': !runtime_1.exists(json, 'dietaryRestrictions') ? undefined : json['dietaryRestrictions'],
        'placeOfOrigin': !runtime_1.exists(json, 'placeOfOrigin') ? undefined : json['placeOfOrigin'],
        'expirationDate': !runtime_1.exists(json, 'expirationDate') ? undefined : json['expirationDate'],
    };
}
exports.CreateListingBodyFromJSONTyped = CreateListingBodyFromJSONTyped;
function CreateListingBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'addressBookEntryID': value.addressBookEntryID,
        'price': value.price,
        'quantity': value.quantity,
        'imageIDs': value.imageIDs,
        'productType': value.productType,
        'dietaryRestrictions': value.dietaryRestrictions,
        'placeOfOrigin': value.placeOfOrigin,
        'expirationDate': value.expirationDate,
    };
}
exports.CreateListingBodyToJSON = CreateListingBodyToJSON;
