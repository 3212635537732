"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardUserToJSON = exports.StandardUserFromJSONTyped = exports.StandardUserFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function StandardUserFromJSON(json) {
    return StandardUserFromJSONTyped(json, false);
}
exports.StandardUserFromJSON = StandardUserFromJSON;
function StandardUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.UserFromJSONTyped(json, ignoreDiscriminator),
        'profilePicture': !runtime_1.exists(json, 'profilePicture') ? undefined : _1.AssetFromJSON(json['profilePicture']),
        'bio': !runtime_1.exists(json, 'bio') ? undefined : json['bio'],
        'numberOfOrdersSold': json['numberOfOrdersSold'],
        'numberOfOrdersPlaced': !runtime_1.exists(json, 'numberOfOrdersPlaced') ? undefined : json['numberOfOrdersPlaced'],
        'avgRating': !runtime_1.exists(json, 'avgRating') ? undefined : json['avgRating'],
        'reviews': !runtime_1.exists(json, 'reviews') ? undefined : (json['reviews'].map(_1.ReviewFromJSON)),
    };
}
exports.StandardUserFromJSONTyped = StandardUserFromJSONTyped;
function StandardUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.UserToJSON(value),
        'profilePicture': _1.AssetToJSON(value.profilePicture),
        'bio': value.bio,
        'numberOfOrdersSold': value.numberOfOrdersSold,
        'numberOfOrdersPlaced': value.numberOfOrdersPlaced,
        'avgRating': value.avgRating,
        'reviews': value.reviews === undefined ? undefined : (value.reviews.map(_1.ReviewToJSON)),
    };
}
exports.StandardUserToJSON = StandardUserToJSON;
