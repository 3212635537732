import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Token, TokensApi} from "client";
import LoginCard from "../components/LoginCard";
import LoginFooter from "../components/LoginFooter";
import {ReactComponent as BackgroundGraphic} from "../svgs/login-gradient_shapes.svg";
import {addError, decrementLoading, incrementLoading, logout} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {useHistory} from "react-router";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const Login: React.FC<IProps> = (props) => {

	const history = useHistory();

	/**
	 * Used to check if a token exists in props
	 * when the page loads, or if it somehow gets
	 * added otherwise in the meantime.
	 *
	 */
	useEffect(() => {
		if (props.fullToken) {
			verifyTokenExpiry(props.fullToken).then().catch();
		}
	}, [JSON.stringify(props.fullToken)]);

	/**
	 * Checks if the existing token is expired.
	 * If it is, dispatch the redux logout action but don't
	 * do anything from the user's perspective - they must log in again.
	 *
	 * If their token ISN'T expired, replace current index in history with the dashboard route.
	 *
	 * @param fullToken
	 */
	async function verifyTokenExpiry(fullToken: Token): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TokensApi(getConfig(fullToken)).checkTokenExpiration({
				tokenBody: {
					token: fullToken?.token,
				},
			});

			if (res.expired) {
				await props.dispatch(logout());
			} else {
				history.push("/dashboard");
			}
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	if (props.fullToken) {
		return null;
	}

	return (
		<div className="login-page">
			<div className="login-page_background-container">
				<div className="login-page_background-container_graphic-container">
					<BackgroundGraphic className="login-page_background-container_graphic-container_graphic"/>
				</div>

				<div className="login-page_background-container_solid"/>
			</div>

			<div className="login-page_content">
				<div className="login-page_content_spacer"/>

				<div className="login-page_content_container">
					<LoginCard/>

					<div className="login-page_content_spacer"/>

					<LoginFooter/>
				</div>

				<div className="login-page_content_spacer"/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		fullToken: store.metaStore.fullToken
	}
})(Login);
