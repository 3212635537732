"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvestorToJSON = exports.InvestorFromJSONTyped = exports.InvestorFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function InvestorFromJSON(json) {
    return InvestorFromJSONTyped(json, false);
}
exports.InvestorFromJSON = InvestorFromJSON;
function InvestorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        '__v': !runtime_1.exists(json, '__v') ? undefined : json['__v'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'creator': _1.GodAdminFromJSON(json['creator']),
        'name': json['name'],
        'walletAddress': json['walletAddress'],
        'tokens': json['tokens'],
        'code': json['code'],
    };
}
exports.InvestorFromJSONTyped = InvestorFromJSONTyped;
function InvestorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        '__v': value.__v,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'creator': _1.GodAdminToJSON(value.creator),
        'name': value.name,
        'walletAddress': value.walletAddress,
        'tokens': value.tokens,
        'code': value.code,
    };
}
exports.InvestorToJSON = InvestorToJSON;
