"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AssetsApi extends runtime.BaseAPI {
    /**
     * Uploads an asset.
     */
    async createAssetRaw(requestParameters) {
        if (requestParameters.asset === null || requestParameters.asset === undefined) {
            throw new runtime.RequiredError('asset', 'Required parameter requestParameters.asset was null or undefined when calling createAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType);
        }
        const response = await this.request({
            path: `/assets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AssetFromJSON(jsonValue));
    }
    /**
     * Uploads an asset.
     */
    async createAsset(requestParameters) {
        const response = await this.createAssetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Gets all assets this user has access to.
     */
    async getAssetsRaw(requestParameters) {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit', 'Required parameter requestParameters.limit was null or undefined when calling getAssets.');
        }
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset', 'Required parameter requestParameters.offset was null or undefined when calling getAssets.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.GetAssetsResponseFromJSON(jsonValue));
    }
    /**
     * Gets all assets this user has access to.
     */
    async getAssets(requestParameters) {
        const response = await this.getAssetsRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetailsRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/assets/storage-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.StorageDetailsFromJSON(jsonValue));
    }
    /**
     * Used to tell how much storage space the user has used, has left, and what the total amount allowed is.
     */
    async getStorageDetails() {
        const response = await this.getStorageDetailsRaw();
        return await response.value();
    }
    /**
     * Updates an existing asset
     */
    async updateAssetRaw(requestParameters) {
        if (requestParameters.assetID === null || requestParameters.assetID === undefined) {
            throw new runtime.RequiredError('assetID', 'Required parameter requestParameters.assetID was null or undefined when calling updateAsset.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.asset !== undefined) {
            formParams.append('asset', requestParameters.asset);
        }
        if (requestParameters.assetID !== undefined) {
            formParams.append('assetID', requestParameters.assetID);
        }
        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name);
        }
        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description);
        }
        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType);
        }
        const response = await this.request({
            path: `/assets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Updates an existing asset
     */
    async updateAsset(requestParameters) {
        await this.updateAssetRaw(requestParameters);
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64Raw(requestParameters) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetBase64.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/assets/base-64/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AssetBase64ResponseFromJSON(jsonValue));
    }
    /**
     * Gets the base64 of a particular asset.
     */
    async viewAssetBase64(requestParameters) {
        const response = await this.viewAssetBase64Raw(requestParameters);
        return await response.value();
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetPipeRaw(requestParameters) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetPipe.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/assets/pipe/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetPipe(requestParameters) {
        const response = await this.viewAssetPipeRaw(requestParameters);
        return await response.value();
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRedirectRaw(requestParameters) {
        if (requestParameters.urlExtension === null || requestParameters.urlExtension === undefined) {
            throw new runtime.RequiredError('urlExtension', 'Required parameter requestParameters.urlExtension was null or undefined when calling viewAssetRedirect.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/assets/redirect/{urlExtension}`.replace(`{${"urlExtension"}}`, encodeURIComponent(String(requestParameters.urlExtension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.BlobApiResponse(response);
    }
    /**
     * pipes the asset from Google Storage.
     */
    async viewAssetRedirect(requestParameters) {
        const response = await this.viewAssetRedirectRaw(requestParameters);
        return await response.value();
    }
}
exports.AssetsApi = AssetsApi;
