"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * reuzes
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 0.2.15-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatusToJSON = exports.OrderStatusFromJSONTyped = exports.OrderStatusFromJSON = exports.OrderStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PENDING"] = "PENDING";
    OrderStatus["COMPLETED"] = "COMPLETED";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
function OrderStatusFromJSON(json) {
    return OrderStatusFromJSONTyped(json, false);
}
exports.OrderStatusFromJSON = OrderStatusFromJSON;
function OrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderStatusFromJSONTyped = OrderStatusFromJSONTyped;
function OrderStatusToJSON(value) {
    return value;
}
exports.OrderStatusToJSON = OrderStatusToJSON;
